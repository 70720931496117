<template>
  <div class="pricingResult">
    <template v-if="payStatus === 'success'">
      <div class="title">
        <div class="titleIcon"><svg t="1682314688466" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3101" width="26" height="26"><path d="M416.832 798.08C400.64 798.08 384.512 791.872 372.16 779.52L119.424 525.76C94.784 500.992 94.784 460.8 119.424 436.032 144.128 411.264 184.128 411.264 208.768 436.032L416.832 644.928 814.4 245.76C839.04 220.928 879.04 220.928 903.744 245.76 928.384 270.528 928.384 310.656 903.744 335.424L461.504 779.52C449.152 791.872 432.96 798.08 416.832 798.08Z" fill="#0A189D" p-id="3102"></path></svg></div>
        <div class="titleText">{{$t('buy.result_title')}}</div>
      </div>
      <div class="subtitle">&#127881; {{$t('buy.result_subtitle')}} &#127881;</div>
      <div class="startLearningBtn" @click="startLearningHandle">{{$t('buy.start_learning')}}</div>
      <div class="divider"></div>
      <template v-if="isMember">
        <div class="successInfo">{{$t('buy.success')}}</div>
        <div class="redirectInfo">{{$t('buy.redirect_now')}}</div>
      </template>
      <template v-else>
        <div class="tips">{{$t('buy.result_tips_1')}}
          <br>{{$t('buy.result_tips_2')}}</div>
        <div class="refreshBtn" :class="{disabled: refreshDisabled}" @click="refreshHandle">
          {{$t('buy.refresh')}}
          <span v-show="refreshDisabled && duration >= 0">({{duration}}s)</span>
        </div>
      </template>
    </template>

    <template v-else>
      <div class="title">
        <div class="titleText">Payment failed.</div>
      </div>
    </template>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { getMemberStatus } from '@/api/auth'
  import { facebookConvertApi } from '@/api/order'
  import { checkIsMember } from '@/utils/memberUtil'

  export default {
    data() {
      return {
        payStatus: '',
        payPrice: '',
        refreshDisabled: true,
        duration: 5
      }
    },
    computed: {
      ...Vuex.mapGetters(['userInfo', 'isMember'])
    },
    props: ['result', 'price'],
    mounted() {
      let params = new URLSearchParams(window.location.search)
      let statusVal = params.get('status')
      let priceVal = params.get('price')
      this.payPrice = this.price || priceVal

      if (this.result === 'success' || statusVal === 'success') {
        this.payStatus = 'success'
        this.countdown()
      } else if (this.result === 'failed' || statusVal === 'failed') {
        this.payStatus = 'failed'
      } else {
        this.$router.push('/pricing')
      }
    },
    watch: {
      userInfo(val) {
        // for促销订单的facebook统计
        if (val && this.payPrice) {
          facebookConvertApi(val.UID, this.payPrice)
        }
      }
    },
    methods: {
      async refreshHandle() {
        if (!this.refreshDisabled) {
          let memberStatus = await getMemberStatus(this.userInfo.UID)
          let isMember = checkIsMember(memberStatus)
          if (isMember) {
            this.$store.commit('set_isMember', isMember)
            setTimeout(() => {
              this.$router.push('/clips')
            }, 2000)
          }
        }
      },
      startLearningHandle() {
        this.$router.push('/clips')
      },
      countdown() {
        let that = this
        let timer = setInterval(() => {
          if (that.duration === 0) {
            that.refreshDisabled = false
            clearInterval(timer)
          } else {
            that.duration --
          }
        }, 1000)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pricingResult {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .divider {
      margin: 65px 0 35px;
      width: 90%;
      max-width: 530px;
      height: 1px;
      background: #FFFFFF;
      opacity: 0.23;
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Arial-Black;
      font-weight: 900;
      font-size: 26px;
      color: #FFFFFF;
      .titleIcon {
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background: #FFE113;
      }
    }
    .subtitle {
      margin-top: 34px;
      font-family: Helvetica;
      font-weight: 700;
      font-size: 22px;
      color: #FFFFFF;
    }
    .startLearningBtn {
      margin-top: 48px;
      padding: 0 50px;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      background: #FFE113;
      box-shadow: 0 5px 10px 0 #00000024;
      font-family: Helvetica;
      font-weight: 700;
      font-size: 16px;
      color: #000000;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #FFCA13;
      }
    }
    .tips, .redirectInfo {
      opacity: 0.8;
      font-family: Helvetica;
      font-size: 12px;
      line-height: 20px;
      color: #FFFFFF;
      text-align: center;
    }
    .refreshBtn {
      margin-top: 24px;
      padding: 0 6px;
      height: 18px;
      line-height: 18px;
      border: 1px solid #6C7189;
      border-radius: 3px;
      font-family: Helvetica;
      font-size: 12px;
      color: #FFFFFF;
      cursor: pointer;
    }
    .successInfo {
      color: red;
      font-size: 24px;
      font-weight: bold;
      font-style: italic;
    }
    .redirectInfo {
      margin-top: 20px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    .pricingResult {
      .subtitle {
        text-align: center;
      }
    }
  }
</style>